/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getMilestoneVideoUrl = /* GraphQL */ `query GetMilestoneVideoUrl(
  $siteCode: String!
  $cameraId: String!
  $startTime: String!
  $endTime: String!
) {
  getMilestoneVideoUrl(
    siteCode: $siteCode
    cameraId: $cameraId
    startTime: $startTime
    endTime: $endTime
  ) {
    statusCode
    body
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMilestoneVideoUrlQueryVariables,
  APITypes.GetMilestoneVideoUrlQuery
>;
export const getBoschVideoUrl = /* GraphQL */ `query GetBoschVideoUrl(
  $siteCode: String!
  $cameraId: String!
  $startTime: String!
  $endTime: String!
) {
  getBoschVideoUrl(
    siteCode: $siteCode
    cameraId: $cameraId
    startTime: $startTime
    endTime: $endTime
  ) {
    statusCode
    body
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBoschVideoUrlQueryVariables,
  APITypes.GetBoschVideoUrlQuery
>;
export const getImageCapture = /* GraphQL */ `query GetImageCapture($siteCode: String!, $cameraId: String!) {
  getImageCapture(siteCode: $siteCode, cameraId: $cameraId) {
    statusCode
    body
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetImageCaptureQueryVariables,
  APITypes.GetImageCaptureQuery
>;
export const getParcelTrackingData = /* GraphQL */ `query GetParcelTrackingData(
  $siteCode: String!
  $trackingID: String!
  $date: String!
) {
  getParcelTrackingData(
    siteCode: $siteCode
    trackingID: $trackingID
    date: $date
  ) {
    inductTime
    stowTime
    pickTime
    stageTime
    dsTimeZone
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetParcelTrackingDataQueryVariables,
  APITypes.GetParcelTrackingDataQuery
>;
export const listAudit = /* GraphQL */ `query ListAudit(
  $startDate: String!
  $endDate: String!
  $limit: Int!
  $offset: Int!
) {
  listAudit(
    startDate: $startDate
    endDate: $endDate
    limit: $limit
    offset: $offset
  ) {
    eventId
    tableName
    actionTstampTx
    action
    rowData
    rowDataJson
    changedFields
    changedFieldsJson
    __typename
  }
}
` as GeneratedQuery<APITypes.ListAuditQueryVariables, APITypes.ListAuditQuery>;
export const listSiteCameras = /* GraphQL */ `query ListSiteCameras($siteCode: String!, $vms: VMS!) {
  listSiteCameras(siteCode: $siteCode, vms: $vms) {
    name
    description
    systemIdentifier
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSiteCamerasQueryVariables,
  APITypes.ListSiteCamerasQuery
>;
export const listCachedSiteCameraList = /* GraphQL */ `query ListCachedSiteCameraList($siteCode: String!) {
  listCachedSiteCameraList(siteCode: $siteCode) {
    name
    description
    systemIdentifier
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCachedSiteCameraListQueryVariables,
  APITypes.ListCachedSiteCameraListQuery
>;
export const listCameraLinksForSite = /* GraphQL */ `query ListCameraLinksForSite($siteCode: String!) {
  listCameraLinksForSite(siteCode: $siteCode) {
    cameras {
      systemIdentifier
      __typename
    }
    created
    createdBy
    siteCode
    stepName
    updated
    updatedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCameraLinksForSiteQueryVariables,
  APITypes.ListCameraLinksForSiteQuery
>;
export const listUserPermission = /* GraphQL */ `query ListUserPermission($path: String!) {
  listUserPermission(path: $path) {
    HASPERMISSION
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPermissionQueryVariables,
  APITypes.ListUserPermissionQuery
>;
export const listUserSites = /* GraphQL */ `query ListUserSites($path: String!) {
  listUserSites(path: $path) {
    SegmentName
    SegmentLocation
    SegmentSource
    SiteCode
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSitesQueryVariables,
  APITypes.ListUserSitesQuery
>;
export const listPilotUserSites = /* GraphQL */ `query ListPilotUserSites {
  listPilotUserSites {
    siteCode
    vms
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPilotUserSitesQueryVariables,
  APITypes.ListPilotUserSitesQuery
>;
export const listSIGInfraUserSites = /* GraphQL */ `query ListSIGInfraUserSites($username: String!) {
  listSIGInfraUserSites(username: $username) {
    allSites
    sites
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSIGInfraUserSitesQueryVariables,
  APITypes.ListSIGInfraUserSitesQuery
>;
