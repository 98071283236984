import {
  Button,
  Container,
  Form,
  SpaceBetween,
  Spinner,
  Table,
  Popover,
  Box,
  Header,
  Pagination,
  CollectionPreferences,
  TextFilter,
  FormField,
  Flashbar,
  RadioGroupProps,
  Tiles,
} from '@amzn/awsui-components-react';
import {
  Camera,
  CameraInput,
  CameraLink,
  CreateCameraLinkInput,
  DeleteCameraLinkInput,
  Step,
  UpdateCameraLinkInput,
} from 'src/API';
import {
  CameraLinkWithCameraNameAndDesc,
  PaginationLabels,
  TableEmptyState,
  TableNoMatchState,
} from './table-config';
import {
  QueryKeys,
  StepOptions,
} from 'src/constants';
import {
  createCameraLink,
  debug,
  deleteCameraLink,
  updateCameraLink,
} from 'src/utils/utils';
import {
  useEffect,
  useState,
} from 'react';
import {
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { fetchCameraImage } from 'src/utils/FetchCameraImage';
import { IconContext } from 'react-icons';
import { IoVideocam } from 'react-icons/io5';
import { SelectOptionInterface } from '../ParcelSearch/ParcelSearchTablePanel';
import { fetchPageSize } from 'src/utils/UserPreferences';
import { ParcelPlaybackBaseState } from 'src/stores/app';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useHookstate } from '@hookstate/core';

export interface CameraLinkPropsInterface {
  cameraLink: CameraLinkWithCameraNameAndDesc | undefined;
  cancelCreateCallback: Function;
  saveCallback: Function;
}

export const CameraLinkForm = (props: CameraLinkPropsInterface) => {
  debug(`CameraLink(): props is ${JSON.stringify(props)}`);

  const parcelPlaybackBaseState = useHookstate(ParcelPlaybackBaseState);

  const cameraLinksQuery = useQuery({ queryKey: [QueryKeys.cameraLinks], enabled: false });
  const camerasQuery = useQuery({ queryKey: [QueryKeys.cameras], enabled: false });

  const [cameraFilterChoice, setCameraFilterChoice] = useState<string>('all');
  const [cameraOptions, setCameraOptions] = useState<SelectOptionInterface[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [pageSize, handlePageSizeChange] = fetchPageSize('create_links', 20);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedCameras, setSelectedCameras] = useState<SelectOptionInterface[]>([]);
  const [selectedStep, setSelectedStep] = useState<string | null>(StepOptions.find(so => so.label === props.cameraLink?.stepName)?.value ?? null);

  const [bundle, isBundleLoading] = useBundle('components.CameraLinks.CameraLinkForm');

  const { timeStamp, blobUrl, cameraImage, loading, fetchImage } = fetchCameraImage();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    cameraOptions,
    {
      filtering: {
        empty: <TableEmptyState title={'Not Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: { pageSize: pageSize },
      sorting: {},
    });

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const itemsCount = (): number => {
    if (cameraOptions) return cameraOptions.length;
    return 0;
  };

  const cancelBtnHandler = () => {
    props.cancelCreateCallback();
  };

  const createCameraLinkMutation = useMutation({
    mutationFn: createCameraLink,
    onError: (error) => {
      console.error('createCameraLinkMutation() error', error);
      setErrorMessage(error.message);
    },
    onSuccess: () => {
      debug('createCameraLinkMutation() onSuccess');
      cameraLinksQuery.refetch();
    },
  });

  const updateCameraLinkMutation = useMutation({
    mutationFn: updateCameraLink,
    onError: (error) => {
      console.error('updateCameraLinkMutation() error', error);
      setErrorMessage(error.message);
    },
    onSuccess: () => {
      debug('updateCameraLinkMutation() onSuccess');
      cameraLinksQuery.refetch();
    },
  });

  const saveBtnHandler = async () => {
    setSaving(true);
    const cameras: CameraInput[] = selectedCameras.map(c => { return({ systemIdentifier: c.value })});
    if (props.cameraLink) {
      const newStepName = StepOptions.find(s => s.value === selectedStep!)?.label! as Step;
      if (newStepName !== props.cameraLink.stepName) {
        const createCameraLinkInput: CreateCameraLinkInput = {
          cameras,
          createdBy: parcelPlaybackBaseState.value.username!,
          stepName: newStepName,
          siteCode: parcelPlaybackBaseState.value.selectedSite?.siteCode!,
        };
        createCameraLinkMutation.mutate(createCameraLinkInput);
        const deleteCameraLinkInput: DeleteCameraLinkInput = {
          partitionKey: `${parcelPlaybackBaseState.value.selectedSite?.siteCode}#${newStepName}`,
        };
        await deleteCameraLink(deleteCameraLinkInput);
      } else {
        const updateCameraLinkInput: UpdateCameraLinkInput = {
          cameras,
          updatedBy: parcelPlaybackBaseState.value.username!,
          stepName: newStepName,
          siteCode: parcelPlaybackBaseState.value.selectedSite?.siteCode!,
        };
      updateCameraLinkMutation.mutate(updateCameraLinkInput);
      }
      setSaving(false);
      props.saveCallback();
      return;
    }
    if (!props.cameraLink) {
      const createCameraLinkInput: CreateCameraLinkInput = {
        cameras,
        createdBy: parcelPlaybackBaseState.value.username!,
        stepName: StepOptions.find(s => s.value === selectedStep!)?.label! as Step,
        siteCode: parcelPlaybackBaseState.value.selectedSite?.siteCode!,
      };
      createCameraLinkMutation.mutate(createCameraLinkInput);
    }
    setSaving(false);
    props.saveCallback();
  };

  const deviceLinkAccessControlDeviceFieldOnChangeHandler = (detail: any) => {
    setSelectedStep(detail.value);
  };

  useEffect(() => {
    setCameraOptions((camerasQuery.data as Camera[]).map(c => (
    {
      label: c.name,
      value: c.systemIdentifier,
    })));
  }, [camerasQuery.data]);

  useEffect(() => {
    if (props.cameraLink?.cameras?.length === 0 ) setSelectedCameras([]);
    if (props.cameraLink?.cameras) {
      setSelectedCameras(props.cameraLink?.camerasWithNameAndDesc?.map(c => (
        {
          label: c.name,
          value: c.systemIdentifier,
        }
      )));
    }
  }, [props.cameraLink?.cameras]);
  
  useEffect(() => {
    if (props.cameraLink) {
      setCameraFilterChoice('all');
      setSelectedStep(StepOptions.find(so => so.label === props.cameraLink?.stepName)?.value ?? null);
    }
    if (!props.cameraLink) {
      setCameraFilterChoice('unlinked');
      setSelectedStep(null);
    }
  }, [props.cameraLink]);

  useEffect(() => {
    const cameraLinks = (cameraLinksQuery.data as CameraLinkWithCameraNameAndDesc[]);
    if (cameraFilterChoice !== 'all' && cameraLinks) {
      const allCameraLinkCameras = cameraLinks.flatMap(cl => cl.camerasWithNameAndDesc);
      setCameraOptions((camerasQuery.data as Camera[])
        .filter(c => cameraFilterChoice === 'unlinked'
          ? !allCameraLinkCameras.find(clc => clc.systemIdentifier === c.systemIdentifier)
          : allCameraLinkCameras.find(clc => clc.systemIdentifier === c.systemIdentifier))
        .map(c => (
          {
            label: c.name,
            value: c.systemIdentifier,
          }
        )));
    } else {
      setCameraOptions((camerasQuery.data as Camera[]).map(c => (
        {
          label: c.name,
          value: c.systemIdentifier,
        }
      )));
    }
  }, [cameraFilterChoice]);

  const FlashMessageError = () => (
    <Flashbar
      items={[
        {
          content: errorMessage,
          dismissible: true,
          onDismiss: () => setErrorMessage(undefined),
          type: 'info',
        },
      ]}
    />
  );
  
  if (isBundleLoading) return <Spinner />;

  return (
    <Container
      header={
        <SpaceBetween size='xs' direction='horizontal'>
          <Button onClick={cancelBtnHandler}>{bundle.getMessage('cancel')}</Button>
          <Button
            disabled={!selectedStep || selectedCameras?.length < 1}
            loading={saving}
            onClick={saveBtnHandler}
            variant='primary'
          >
            {bundle.getMessage('save')}
          </Button>
        </SpaceBetween>
      }
    >
      {errorMessage && <FlashMessageError />}
      <form
        onSubmit={event => {
          event.preventDefault();
          saveBtnHandler();
        }}
      >
        <Form>
          <SpaceBetween size='s' direction='vertical'>
            <Container
              header={<h3>Step</h3>}
            >
              <FormField>
                <Tiles
                  columns={4}
                  onChange={({ detail }) => deviceLinkAccessControlDeviceFieldOnChangeHandler(detail)}
                  value={selectedStep}
                  items={(StepOptions as RadioGroupProps.RadioButtonDefinition[])
                    .sort((a,b) =>
                      StepOptions.find(so => so.value === a.value)?.sequence! < StepOptions.find(so => so.value === b.value)?.sequence!
                      ? -1
                      : 1)
                    .map(so => 
                      ({
                        description: so.description,
                        disabled: !((cameraLinksQuery.data as CameraLink[]).find(cl => cl.stepName === so.label) === undefined),
                        label: so.label,
                        value: so.value,
                        image: selectedStep === so.value
                          ? <img src='/favicon.png' />
                          : <></>,
                      })
                  )}
                />
              </FormField>
            </Container>
            <Table
              columnDefinitions={[
                {
                  id: 'cameraName',
                  header: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <SpaceBetween size='xs' direction='horizontal'>
                        <div style={{ marginRight: '8px' }}>
                          {`${bundle.getMessage('camera')}`}
                        </div>
                        <Tiles
                          columns={5}
                          items={[
                            { label: bundle.getMessage('all'), value: 'all', },
                            { label: bundle.getMessage('linked'), value: 'linked', },
                            { label: bundle.getMessage('unlinked'), value: 'unlinked', },
                          ]}
                          onChange={({ detail }) => setCameraFilterChoice(detail.value)}
                          value={cameraFilterChoice}
                        />
                      </SpaceBetween>
                    </div>
                  ),
                  cell: (item) => (
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {item.label}
                        <Popover
                          triggerType='custom'
                          dismissButton={false}
                          size='large'
                          content={
                            <Box>
                              {loading
                              ?
                                <Spinner />
                              :
                                cameraImage
                                &&
                                <>
                                  <img
                                    src={cameraImage}
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'contain',
                                    }}
                                  />
                                  {blobUrl
                                  &&
                                  <>
                                    <a href={blobUrl} target='_blank' rel='noopener noreferrer'>
                                      {bundle.getMessage('image-full-size')}
                                    </a>
                                    <div>{timeStamp}</div>
                                  </>}
                                </>}
                            </Box>
                          }
                        >
                          <IconContext.Provider
                            value={{
                              style: {
                                verticalAlign: 'middle',
                                cursor: 'pointer',
                                marginLeft: '8px',
                                width: '16px',
                                height: '16px',
                              },
                            }}
                          >
                            <IoVideocam onClick={() => fetchImage(item.value)} />
                          </IconContext.Provider>
                        </Popover>
                      </div>
                    </div>
                  ),
                },
              ]}
              filter={
                <SpaceBetween direction='horizontal' size='s'>
                  <TextFilter
                    {...filterProps}
                    filteringAriaLabel='Filter Cameras'
                    filteringPlaceholder={bundle.getMessage('find-camera')}
                    countText={getFilterCounterText(
                      filteredItemsCount === undefined ? 0 : filteredItemsCount,
                    )}
                  />
                  <Button
                    iconName='refresh'
                    loading={camerasQuery.fetchStatus === 'fetching' || cameraLinksQuery.fetchStatus === 'fetching'} 
                    onClick={event => {
                      event.preventDefault();
                      camerasQuery.refetch();
                    }}
                  />
                </SpaceBetween>
              }
              empty={bundle.getMessage('no-cameras-found')}
              header={<Header counter={`(${itemsCount().toString()})`}>{bundle.getMessage('cameras')}</Header>}
              items={items}
              key={'cameraLinkCamerasTable'}
              loading={
                camerasQuery.fetchStatus === 'fetching'
                || camerasQuery.isFetching
                || camerasQuery.isLoading
              }
              onSelectionChange={({ detail }) => setSelectedCameras(detail.selectedItems || [])}
              pagination={<Pagination {...paginationProps} ariaLabels={PaginationLabels} />}
              preferences={
                <CollectionPreferences
                  onConfirm={({ detail }) => handlePageSizeChange(detail.pageSize || pageSize)}
                  title={bundle.getMessage('user-preferences')}
                  confirmLabel={bundle.getMessage('confirm')}
                  cancelLabel={bundle.getMessage('cancel')}
                  preferences={{
                    pageSize: pageSize,
                  }}
                  pageSizePreference={{
                    title: bundle.getMessage('select-page-size'),
                    options: [
                      { value: 25, label: `25 ${bundle.getMessage('cameras')}` },
                      { value: 50, label: `50 ${bundle.getMessage('cameras')}` },
                      { value: 100, label: `100 ${bundle.getMessage('cameras')}` },
                      { value: 150, label: `150 ${bundle.getMessage('cameras')}` },
                      { value: 250, label: `250 ${bundle.getMessage('cameras')}` },
                      { value: 500, label: `500 ${bundle.getMessage('cameras')}` },
                    ],
                  }}
                />
              }
              selectedItems={selectedCameras}
              selectionType='multi'
              trackBy='value'
            />
          </SpaceBetween>
        </Form>
      </form>
    </Container>);
};
