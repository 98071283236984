import { API, graphqlOperation } from 'aws-amplify';
import {
  CameraLink,
  Camera,
  CreateCameraLinkInput,
  CreateCameraLinkMutation,
  DeleteCameraLinkInput,
  ListCameraLinksForSiteQuery,
  ListPilotUserSitesQuery,
  ListSiteCamerasQuery,
  PilotSite,
  VMS,
  DeleteCameraLinkMutation,
  UpdateCameraLinkInput,
  UpdateCameraLinkMutation,
  GetParcelTrackingDataQuery,
  AthenaResponse,
  ListCachedSiteCameraListQuery,
} from 'src/API';
import {
  listCameraLinksForSite,
  listSiteCameras,
  listPilotUserSites,
  getParcelTrackingData,
  listCachedSiteCameraList,
} from 'src/graphql/queries';
import {
  createCameraLink as createCameraLinkMutation,
  deleteCameraLink as deleteCameraLinkMutation,
  updateCameraLink as updateCameraLinkMutation,
} from 'src/graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api';
import { isAfter } from 'date-fns/isAfter';
import { subDays } from 'date-fns/subDays';
import { debugLogging } from 'src/components/App';
import { startOfDay } from 'date-fns';

export function debug(message: string) {
  try {
    debugLogging && console.debug(message);
  } catch (error) {
    if (error instanceof TypeError) console.debug(message);
    else console.error(error);
  }
}

export function getTimeString(timestamp: number) {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

export const getUserSites = async (username: string, stage: string): Promise<PilotSite[]> => {
  debug(`getUserSites() username is ${username} stage is ${stage}`);

  let userSites: PilotSite[] = [];

  try {
    const userSitesResponse = await API.graphql(graphqlOperation(listPilotUserSites, {})) as GraphQLResult<ListPilotUserSitesQuery>;
    const allSites = userSitesResponse.data?.listPilotUserSites as PilotSite[];
    userSites = allSites;
  } catch (error) {
    console.error(`getUserSites(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  return userSites;
};

export const createCameraLink = async (createCameraLinkInput: CreateCameraLinkInput): Promise<CameraLink | undefined> => {
  debug(`createCameraLink() createCameraLinkInput is ${JSON.stringify(createCameraLinkInput)}`);

  let cameraLink: CameraLink | undefined = undefined;

  try {
    const response = (await API.graphql(
      graphqlOperation(createCameraLinkMutation, {
        input: createCameraLinkInput,
      }),
    )) as GraphQLResult<CreateCameraLinkMutation>;
    debug(`createCameraLink() response is ${JSON.stringify(response)}`);
    cameraLink = response.data?.createCameraLink as CameraLink;
  } catch (error) {
    console.error(`createCameraLink(): error is ${JSON.stringify(error)}`);
  }

  debug(`createCameraLink() cameraLink is ${JSON.stringify(cameraLink)}`);
  return cameraLink;
};

export const getCameraLinks = async (siteCode: string): Promise<CameraLink[]> => {
  debug(`getCameraLinks() siteCode is ${siteCode}`);

  let cameraLinks: CameraLink[] = [];

  try {
    const response = (await API.graphql(
      graphqlOperation(listCameraLinksForSite, {
        siteCode,
      }),
    )) as GraphQLResult<ListCameraLinksForSiteQuery>;
    cameraLinks = response.data?.listCameraLinksForSite as CameraLink[];
  } catch (error) {
    console.error(`getCameraLinks(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  return cameraLinks;
};

export const updateCameraLink = async (updateCameraLinkInput: UpdateCameraLinkInput): Promise<CameraLink | undefined> => {
  debug(`updateCameraLink() updateCameraLinkInput is ${JSON.stringify(updateCameraLinkInput)}`);

  let cameraLink: CameraLink | undefined = undefined;

  try {
    const response = (await API.graphql(
      graphqlOperation(updateCameraLinkMutation, {
        input: updateCameraLinkInput,
      }),
    )) as GraphQLResult<UpdateCameraLinkMutation>;
    debug(`updateCameraLink() response is ${JSON.stringify(response)}`);
    cameraLink = response.data?.updateCameraLink as CameraLink;
  } catch (error) {
    console.error(`updateCameraLink(): error is ${JSON.stringify(error)}`);
  }

  debug(`updateCameraLink() cameraLink is ${JSON.stringify(cameraLink)}`);
  return cameraLink;
};

export const deleteCameraLink = async (deleteCameraLinkInput: DeleteCameraLinkInput): Promise<CameraLink | undefined> => {
  debug(`deleteCameraLink() deleteCameraLinkInput is ${JSON.stringify(deleteCameraLinkInput)}`);

  let cameraLink: CameraLink | undefined = undefined;

  try {
    const response = (await API.graphql(
      graphqlOperation(deleteCameraLinkMutation, {
        input: deleteCameraLinkInput,
      }),
    )) as GraphQLResult<DeleteCameraLinkMutation>;
    debug(`deleteCameraLink() response is ${JSON.stringify(response)}`);
    cameraLink = response.data?.deleteCameraLink as CameraLink;
  } catch (error) {
    console.error(`deleteCameraLink(): error is ${JSON.stringify(error)}`);
  }

  debug(`deleteCameraLink() cameraLink is ${JSON.stringify(cameraLink)}`);
  return cameraLink;
};

export const getCameras = async (siteCode: string, vms: VMS): Promise<Camera[]> => {
  debug(`getCameras() siteCode is ${siteCode} vms is ${vms}`);

  const cameras: Camera[] = [];
  let next = true;

  while (next) {
    try {
      const listCamerasResponse = (await API.graphql(
        graphqlOperation(listSiteCameras, {
          siteCode,
          vms,
        }),
      )) as GraphQLResult<ListSiteCamerasQuery>;
      debug(`getCameras() listCamerasResponse is ${JSON.stringify(listCamerasResponse)}`);
      if (listCamerasResponse.data?.listSiteCameras === null) throw new Error('getCameras returned null');
      for (let camera of listCamerasResponse.data?.listSiteCameras as Camera[]) {
        cameras.push(camera);
      }
      debug(`getCameras() cameras.length is ${cameras.length}`);
      next = false; // TODO: added pagination
    } catch (error) {
      console.error(`getCameras() error is ${error} JSON.stringify(error) is ${JSON.stringify(error)}`);
      throw error;
    }
  }

  debug(`getCameras() cameras.length is ${cameras.length}`);
  return cameras.sort((a, b) => a.name < b.name ? -1 : 1);
};

export const getCachedCameras = async (siteCode: string): Promise<Camera[]> => {
  debug(`getCachedCameras() siteCode is ${siteCode}`);

  const cameras: Camera[] = [];
  let next = true;

  while (next) {
    try {
      const response = (await API.graphql(
        graphqlOperation(listCachedSiteCameraList, {
          siteCode,
        }),
      )) as GraphQLResult<ListCachedSiteCameraListQuery>;
      debug(`getCachedCameras() response is ${JSON.stringify(response)}`);
      if (response.data?.listCachedSiteCameraList === null) throw new Error('getCachedCameras returned null');
      for (let camera of response.data?.listCachedSiteCameraList as Camera[]) {
        cameras.push(camera);
      }
      debug(`getCachedCameras() cameras.length is ${cameras.length}`);
      next = false; // TODO: added pagination
    } catch (error) {
      console.error(`getCachedCameras() error is ${error} JSON.stringify(error) is ${JSON.stringify(error)}`);
      throw error;
    }
  }

  debug(`getCachedCameras() cameras.length is ${cameras.length}`);
  return cameras.sort((a, b) => a.name < b.name ? -1 : 1);
};

export const getParcelTracking = async (siteCode: string, trackingId: string, date: string): Promise<AthenaResponse | undefined> => {
  debug(`getParcelTracking() siteCode is ${siteCode} trackingId is ${trackingId} date is ${date}`);

  let parcelTrackingData: AthenaResponse;

  try {
    const response = (await API.graphql(graphqlOperation(getParcelTrackingData, {
      siteCode,
      trackingID: trackingId,
      date,
    }),)) as GraphQLResult<GetParcelTrackingDataQuery>;
    debug(`getParcelTracking() response is ${JSON.stringify(response)}`);
    if (!response.data?.getParcelTrackingData || !response.data.getParcelTrackingData[0]) return undefined;
    parcelTrackingData = response.data.getParcelTrackingData[0];
  } catch (error) {
    console.error(`getParcelTracking() error is ${error} JSON.stringify(error) is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`getParcelTracking() parcelTrackingData is ${JSON.stringify(parcelTrackingData)}`);
  return parcelTrackingData;
};

export const validDate = (date: Date, postDays: number) => {
  debug(`validDate() date is ${date} postDays is ${postDays}`);
  if (!date) return false;
  const currentDate = startOfDay(Date.now());
  const earliestDate = subDays(currentDate, postDays);
  debug(`validDate() date is ${date} currentDate is ${currentDate} postDays is ${postDays} earliestDate is ${earliestDate}`);
  return(isAfter(date, earliestDate));
};
